import * as React from 'react'
import { Box, Typography, makeStyles, Theme, createStyles, Grid, Container } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      width: '100%',
      height: '50vh',
      textAlign: 'left'
    },
    heroImage: {
      height: '100%',
      width: '100%',
      backgroundColor: grey[900],
      paddingTop: '3vh',
      paddingBottom: '3vh'
    },
    logoImage: {
      height: '40vh',
      width: '40vh'
    },
    title: {
      fontWeight: 'bold',
      marginBottom: 4
    },
    primary: {
      color: theme.palette.primary.main
    },
    secondary: {
      color: theme.palette.secondary.main
    }
  })
)

type HeroProps = {
  title: string
  subtitle: string
}

const Hero = ({ title, subtitle }: HeroProps) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImageFileName: file(relativePath: { eq: "images/hero.webp" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.heroImage}>
      <Container maxWidth="md">
        <Typography variant="body1">
          1. var bob = new Person();<br />
          2. bob.sayHello();<br />
          3. bob.wave();<br />
          ##################################################################################################################
        </Typography>
        <Typography className={`${classes.title}`} variant="h2">
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        <Typography variant="body1">
          ################################################################################################################## <br />
          4. var cup = new Coffee();<br />
          5. bob.drink(cup);<br />
          6. var cupTwo = new Coffee();<br />
          {subtitle ? '' : '7. bob.drink(cupTwo)'}
        </Typography>
      </Container>
    </div>
  )
}

export default Hero
